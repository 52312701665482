import "./style/style.css";
import About from "./component/About";
import PluginList from "./component/Pluginlist";
import ProjectList from "./component/ListProject";
import React, { useEffect, useState } from 'react';
import ConceptList from "./component/ConceptList";



function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Установим состояние в true через короткую задержку,
    // чтобы гарантировать, что это произойдет после монтирования
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100); // Задержка в 100 мс

    // Очистим таймер, если компонент размонтируется
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex-container">
      <div className={`container ${loaded ? 'loaded' : ''}`}>
        <About />
        <PluginList />
        <ProjectList />
        <ConceptList />
      </div>
    </div>
  );
}

export default App;