import React, { useEffect, useState } from "react";
import Button from "./button";
import Lottie from "lottie-react";

// Функция для замены пробелов после предлогов на неразрывные пробелы
const replaceSpaces = (text) => {
  const prepositions = ["с", "в", "на", "за", "по", "к", "о", "у", "я", "и", "и"];
  const regex = new RegExp(`\\s(${prepositions.join("|")})\\s`, "g");
  return text.replace(regex, (match, p1) => ` ${p1}&nbsp;`);
};

const Plugin = ({
  title,
  description,
  lottieData, // Теперь это URL
  buttonText,
  buttonIcon,
  buttonVariant,
  href,
  iconPosition,
}) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(lottieData);
      if (!response.ok) {
        console.error("Ошибка при загрузке Lottie-анимации:", response.statusText);
        return;
      }
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, [lottieData]);

  // Обработать описание перед рендерингом
  const processedDescription = replaceSpaces(description);

  return (
    <div className="content-plugin">
      {animationData && <Lottie className="plugin-img" animationData={animationData} loop={true} />}
      <div className="content-plugin-list">
        <div className="content-plugin-text">
          <h4>{title}</h4>
          <p dangerouslySetInnerHTML={{ __html: processedDescription }}></p>
        </div>
        <Button
          text={buttonText}
          icon={buttonIcon}
          showIcon={true}
          variant={buttonVariant}
          href={href}
          iconPosition={iconPosition}
        />
      </div>
    </div>
  );
};

export default Plugin;
