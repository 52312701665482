import React from "react";
import Plugin from "./plugin";

// Functional component
const PluginList = () => {
  return (
    <div className="Plugin-container">
      <h3>Создал свои плагины для Figma и не только</h3>
      <div className="Plugin-list">
        <Plugin
          title="Mastergen"
          description="Плагин легко преобразует любое количество объектов в мастер-компоненты и преобразует их в экземпляры."
          lottieData="/mastergen.json" // Указываем правильный путь к Lottie-файлу
          buttonText="Figma"
          buttonIcon="/arrow.svg"
          buttonVariant="Link"
          href="https://www.figma.com/community/plugin/1338222131701483347"
          iconPosition="end"
        />
        <Plugin
          title="FrameMove"
          description="Позволяет позволяет быстро выбрать и переместить копии необходимых элементов на отдельную страницу одним щелчком."
         lottieData="/framemove.json"
          buttonText="Figma"
          buttonIcon="/arrow.svg"
          buttonVariant="Link"
          href="https://www.figma.com/community/plugin/1338222131701483347"
          iconPosition="end"
        />
          <Plugin
              title="Figma giga backup"
              description="Этот скрипт позволяет сделать бэкап проектов команды Figma и Figjam, а также личных проектов с Figma, в удобном формате. (в любой момент он может перестать работать)"
              lottieData="/git.json"
              buttonText="Github"
              buttonIcon="/arrow.svg"
              buttonVariant="Link"
              href="https://www.figma.com/community/plugin/1338222131701483347"
              iconPosition="end"
          />
      </div>

    </div>


  );
};

export default PluginList;
