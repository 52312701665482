import React from "react";

const Concept = ({ imageUrl }) => {
    return (
        <div className="ConceptImage">
            <img src={imageUrl} alt="Concept" loading="lazy" />
        </div>
    );
};

export default Concept;
