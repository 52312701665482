import React from "react";
import Concept from "./Concept"; // Импорт компонента Concept


const ConceptProjectList = () => {
    const concepts = [
        {
            imageUrl: "/images/1.png",
        },
        {
            imageUrl: "/images/2.png",
        },
        {
            imageUrl: "/images/3.png",
        },
        {
            imageUrl: "/images/4.png",
        },
    ];

    return (
        <div className="ConceptDiv">
            <div className="HeaderProject">
                <h2>Концепты</h2> {/* Заголовок без переключателя */}
            </div>

            <div className="ConceptList">
                {concepts.map((concept, index) => (
                    <Concept key={index} imageUrl={concept.imageUrl} />
                ))}
            </div>
        </div>
    );
};

export default ConceptProjectList;
