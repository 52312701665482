import React, { useRef } from "react";
import Button from "./button";

const Project = ({
  title,
  imageUrl,
  description,
  category,
  year,
  buttonText,
  buttonIcon,
  buttonHref,
  projectType
}) => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  const handleMouseEnter = () => {
    const img = imageRef.current;
    const container = containerRef.current;

    if (img && container) {
      const containerHeight = container.clientHeight;
      const imgHeight = img.clientHeight;
      const offset = 159; // расстояние, которое должно оставаться снизу

      const translateY = Math.max(imgHeight - containerHeight + offset, 0);
      img.style.transition = 'transform 5s linear'; // Устанавливаем время анимации на линейное
      img.style.transform = `translateY(-${translateY}px)`; // Поднимаем изображение
    }
  };

  const handleMouseLeave = () => {
    if (imageRef.current) {
      imageRef.current.style.transition = 'transform 1s linear'; // Устанавливаем время анимации на линейное
      imageRef.current.style.transform = 'translateY(0)'; // Возвращаем на место
    }
  };

  return (
    <div className="ProjectInfo" data-project-type={projectType} ref={containerRef}>
      <div className="ProjectImage" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img ref={imageRef} src={imageUrl} alt={description} style={{ transition: 'transform 5s linear' }} />
      </div>
      <div className="ProjectInfoText">
        <h3>{title}</h3>
        <div className="ProjectLabel">
          <p>{category}</p> <p>·</p> <p>{year}</p>
        </div>
        <Button
          text={buttonText}
          icon={buttonIcon}
          showIcon={true}
          variant="Link"
          iconPosition="end"
          href={buttonHref}
        />
      </div>
    </div>
  );
};

export default Project;
