import React, { useState } from "react";
import Project from "./Project";

const ProjectList = () => {
    const [selectedType, setSelectedType] = useState("web"); // Состояние для выбранного типа проектов
    const [fade, setFade] = useState(false); // Состояние для управления плавным переходом

    const handleTypeChange = (type) => {
        if (type !== selectedType) {
            setFade(true); // Начинаем анимацию исчезновения
            setTimeout(() => {
                setSelectedType(type); // Меняем тип проектов
                setFade(false); // Начинаем анимацию появления
            }, 300); // Время для завершения анимации исчезновения
        }
    };

    const projects = [
        {
            title: "Алмила",
            imageUrl: "/project-img/almila.png",
            description: "Алмила",
            category: "E-commerce",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://almila.ru/",
            projectType: "web",
        },
        {
            title: "Футбольная академия «Искра»",
            imageUrl: "/project-img/iskra.png",
            description: "Футбольная академия «Искра»",
            category: "Корпоротивный сайт",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://iskrafootball.ru/",
            projectType: "web",
        },
        {
            title: "Ерофей арена",
            imageUrl: "/project-img/miratorg.png",
            description: "Мираторг растениеводство",
            category: "Корпоротивный сайт",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://miratorg.ru/crops/",
            projectType: "web",
        },
        {
            title: "АПИРИ",
            imageUrl: "/project-img/apiri.png",
            description: "АПИРИ",
            category: "Корпоротивный сайт",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://invest.khv.gov.ru/",
            projectType: "web",
        },
        {
            title: "Ерофей Арена",
            imageUrl: "/project-img/erof.png",
            description: "Ерофей Арен",
            category: "Корпоротивный сайт/Касса",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://erofey-arena.com/",
            projectType: "web",
        },

        {
            title: "Солид Банк",
            imageUrl: "/project-img/solidbank.png",
            description: "Фин-тех",
            category: "Фин-тех",
            year: "2024",
            buttonText: "Перейти",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://solidbank.ru/",
            projectType: "web",
        },

        {
            title: "Тафгай",
            imageUrl: "/project-img/taf.png",
            description: "E-commerce",
            category: "E-commerce",
            year: "2024",
            buttonText: "В разработки NDA",
            buttonIcon: "",
            buttonHref: "",
            projectType: "mobile",
        },

        {
            title: "Пеликан",
            imageUrl: "/project-img/pelican.png",
            description: "E-commerce",
            category: "E-commerce",
            year: "2024",
            buttonText: "В разработки NDA",
            buttonIcon: "",
            buttonHref: "",
            projectType: "mobile",
        },

        {
            title: "Аптекарь.рф",
            imageUrl: "/project-img/aptek.png",
            description: "E-commerce",
            category: "E-commerce",
            year: "2023",
            buttonText: "Проект закрыт",
            buttonIcon: "",
            buttonHref: "",
            projectType: "mobile",
        },

        {
            title: "Ecoplace",
            imageUrl: "/project-img/ecoplace.png",
            description: "E-commerce",
            category: "E-commerce",
            year: "2022",
            buttonText: "App store",
            buttonIcon: "./arrow.svg",
            buttonHref: "https://apps.apple.com/tc/app/ecoplace/id1568046458",
            projectType: "mobile",
        },
    ];

    const filteredProjects = projects.filter(
        (project) => project.projectType === selectedType
    );

    return (
        <div className="ProjectDiv">
            <div className="HeaderProject">
                <h2>
                    Над чем работал{" "}
                    <span
                        className={`filter-option ${selectedType === "web" ? "active" : ""}`}
                        onClick={() => handleTypeChange("web")}
                    >
            web
          </span>{" "}
                    <span
                        className={`filter-option ${selectedType === "mobile" ? "active" : ""}`}
                        onClick={() => handleTypeChange("mobile")}
                    >
            mobile
          </span>
                </h2>
            </div>

            <div className={`ProjectList ${fade ? "fade-out" : "fade-in"}`}>
                {filteredProjects.map((project, index) => (
                    <Project
                        key={index}
                        title={project.title}
                        imageUrl={project.imageUrl}
                        description={project.description}
                        category={project.category}
                        year={project.year}
                        buttonText={project.buttonText}
                        buttonIcon={project.buttonIcon}
                        buttonHref={project.buttonHref}
                        projectType={project.projectType}
                    />
                ))}
            </div>

            <p className="ProjectDivText">
                и еще 100+ проектов и доработок, которые не вошли в список
            </p>
        </div>
    );
};

export default ProjectList;
