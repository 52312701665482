import React from "react";

const Button = ({
  text,
  icon,
  showIcon,
  variant,
  href,
  iconPosition = "start",
}) => {
  const buttonContent = (
    <>
      {showIcon && icon && iconPosition === "start" && (
        <img src={icon} alt="icon" className="button-icon" />
      )}
      {text}
      {showIcon && icon && iconPosition === "end" && (
        <img src={icon} alt="icon" className="button-icon" />
      )}
    </>
  );

  return href ? (
    <a
      href={href}
      className={`${variant}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {buttonContent}
    </a>
  ) : (
    <button className={`${variant}`}>{buttonContent}</button>
  );
};

export default Button;
