import React from "react";
import Button from "./button";

// Функция для замены пробелов после предлогов на неразрывные пробелы
const replaceSpaces = (text) => {
  const prepositions = ["с", "в", "на", "за", "по", "к", "о", "у", "я", "и", "5-"];
  const regex = new RegExp(`\\s(${prepositions.join("|")})\\s`, "g");
  return text.replace(regex, (match, p1) => ` ${p1}&nbsp;`);
};

// Функциональный компонент
const About = () => {
  const text1 = "Привет! Меня зовут Максим, я UX/UI дизайнер с более чем 5-летним опытом работы, стремящийся создавать интуитивные и приятные в использовании интерфейсы.";
  const text2 = "Большую часть времени я работал в студии и сотрудничал с Web3.0 стартапами, помогая создавать классные сайты и приложения.";
  
  return (
    <div className="about-container">
      <div className="about-text">
        <h2 dangerouslySetInnerHTML={{ __html: replaceSpaces(text1) }} />
        <h2 dangerouslySetInnerHTML={{ __html: replaceSpaces(text2) }} />
      </div>
      <div className="button-list">
        <Button
          text="Telegram"
          icon="./telegram.svg"
          showIcon={true}
          variant="Primary"
          iconPosition="start"
          href="https://t.me/Zombikot"
        />
        <Button
          text="CV"
          icon="./telegram.svg"
          showIcon={true}
          variant="Primary"
          iconPosition="start"
          href="./cv.pdf"
        />
      </div>
    </div>
  );
};

export default About;
